@font-face {
       		 font-family: 'C';
       		 src: url('fonts/Roboto-Condensed-webfont.eot');
       		 src: url('fonts/Roboto-Condensed-webfont.eot?#iefix') format('embedded-opentype'),
             url('fonts/Roboto-Condensed-webfont.woff') format('woff'),
             url('fonts/Roboto-Condensed-webfont.ttf') format('truetype'),
             url('fonts/Roboto-Condensed-webfont.svg#robotolight') format('svg');

}

@font-face {
       		 font-family: 'Robo';
       		 src: url('fonts/Roboto-Regular-webfont.eot');
       		 src: url('fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
             url('fonts/Roboto-Regular-webfont.woff') format('woff'),
             url('fonts/Roboto-Regular-webfont.ttf') format('truetype'),
             url('fonts/Roboto-Regular-webfont.svg#robotolight') format('svg');

}


.witchHeader h2 {
	color:#7b2cea;
	font-size:22px;
	font-family:'Tablet_Gothic_Condensed','Arial';
	font-weight:bold;
	margin-top:5px;
}

.astroMenu {
 	background:url("https://img2.cncenter.cz/images/blesk-2013/astrodny/star-bg.png") no-repeat transparent;
 	width:100%;
 	margin-left:10px;
	margin-top:15px;

}

.astroMenu  h2 {
	font-size: 25px;
	color: #fff;
	border: 1px solid #fff;
	display: inline;
	padding: 3px;
	position: absolute;
	margin: 15px 20px 10px 10px;
	border-radius: 5px;
	text-shadow: 0px 0px 1px rgba(255,255,255,1);
	color: transparent;
	box-shadow: 0px 0px 1px 1px #fff;

}

.program-chatu .pic {
	position:absolute;
	top:-75px;
	height:75px;
	left:0;
}
.program-chatu {
	background:#fa5ab9;
	/*width:100%;*/
	margin-top:65px;
}
.program-chatu .program-header   {
	float:left;
	color:#fff;
	font-family:'Arial';
	font-size:13px;
	display:inline-block;
	margin:12px 5px 0 10px;
	font-weight: bold;
}
.program-chatu ul {
  text-align: left;
  display: inline;
  margin: 0 0 0 20px;
  padding: 15px 4px 17px 0;
  list-style: none;

}
.program-chatu  ul li {
  line-height: 14px;
  display: inline-block;
  margin-right: -4px;
  position: relative;
  padding: 0px 0px;
  cursor: pointer;
  width:82px;
  vertical-align:top;
 text-align: center;

}

.program-chatu  ul li a {
	display: block;
	text-decoration: none;
	 padding: 10px 2px;
	 font-family: 'Robo';
	 font-size:11px;
	 color:#fff;
	 height:20px;
}
.program-chatu  ul li a:hover {background:#dc3296;}

.program-chatu  ul li a:hover .witchDate {visibility:visible;}
.program-chatu  ul li a:hover .witchName {visibility:hidden;}

.program-chatu  ul li:hover {
 	background:#dc3296;

}
.program-chatu .witchWrapp {position: relative;text-align: center;margin-top:-5px;}
.program-chatu .witchDate {position:absolute;left:0;top:0;visibility:hidden;text-align: center;width:100%;}
.program-arrow {
	width: 7px;
	height: 9px;
	display:inline-block;
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -680px 0px transparent;
	margin:14px 20px 0 10px;
}
.rounder {
	display:inline-block;
	border:4px solid #fa5ab9;;
	border-radius:4px;
    margin:0 10px 3px 10px;
}
.astrodny-nadpis {
	background: url('/images/blesk-2013/astrodny/astrodny-nadpis.png?v=2') no-repeat;
	width:184px;
	height:42px;
	display:inline-block;
	position: absolute;
	margin: 15px 20px 10px 10px;
}
.knihaPic {background:url("https://img2.cncenter.cz/images/blesk-2013/astrodny/lecitele-kniha.png?v=2") no-repeat;
	width:180px;
	height:150px;
	margin:15px auto;
}
.talk-banner {
	background:url("https://img2.cncenter.cz/images/blesk-2013/astrodny/nove-horoskopy-banner.png") no-repeat;margin:0 0 10px -80px;
	width:620px;height:98px;
	display:block;
}
.programAstro h3 {
  	margin-top:-30px;
}
.programAstro a {
	color:#7b2cea;
}
.programAstro span {
	display: inline-block;margin: 0 0 3px 20px;
	width: 20px;
	height: 11px;
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -93px -215px transparent;
}
.banner-pic {
	margin:10px 0;
}

/*postrani pravy sloupec */
.videoWrapp {padding:10px;border-top:solid 4px #fa5ab9;font-family:'tablet_gothic_condensed';background:#fff;margin:10px auto;}
.circlePlay {margin-top:-2px;border-radius:20px;width:20px;height:20px;border:2px solid #fa5ab9;float:left;margin-bottom:10px;}
.videoWrapp h2 a {font-size:22px;color:black;text-align:left;}
.videoChat {width:250px;height:150px;margin-bottom:10px;}
.chatLive {background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat -192px -211px;
float:left;width:15px;display:block;
height: 15px;}
.chatLiveText  {color:#fa5ab9;display:block;float:left;font-size:20px;width:100px;margin-left:10px;}
.knihaWrapp {padding:10px;border-top:solid 4px #fa5ab9;background:#fff;margin:10px 0;}
.pinkText {color:#fa5ab9;}
.posleteSms {line-height:140%}

li.boxik_1 {width:84px!important;}
li.boxik_3 {width:75px!important;}
li.boxik_4 {width:90px!important;}
li.boxik_5 {width:80px!important;}
li.boxik_6 {width:77px!important;}
li.boxik_7 {width:77px!important;}
li.boxik_8 {width:77px!important;}
li.boxik_9 {width:88px!important;}

.fotka_3, .fotka_6 {left:-5px!important;}
.fotka_5 {left:-20px!important;}
.fotka_8 {left:-10px!important;}
.zbyvajiciSms {font-size:13px;float:right;margin-right:15px;}
